import React from "react"
import { Link } from "gatsby"
import * as ROUTES from '../constants/routes';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navlinks from '../components/nav';
import Helmet from "react-helmet";
import { AuthUserContext } from '../components/Session';

const LandingPage = () => (
  <React.Fragment>
    <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className="home" />
      </Helmet>
      <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <div className="headerTitle">Home</div>
          <Navlinks />
        </div>
      </header>
      <section id="home-content" className="flexContainer secondaryTab">
        <div className="fCol12 maxTablet">
          <div className="flexContainer homeInner">
              <span className="icon-avatar"><span className="path1"></span><span className="path2"></span><span className="path3"></span></span>
              <h1>Welcome Guest</h1>
            <Link to={ROUTES.US}>U.S. Products</Link>
            <a className="disabled" href="">Canada Products</a>
            <Link to={ROUTES.COMPANY} title="Company Information">Company Information</Link>
            <hr />
          </div>
          <div className="compassLogo"/>
        </div>
      </section>    
  </React.Fragment>
);

export default () => (
  <Layout>
    <LandingPage />
  </Layout>
);